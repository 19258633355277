import React from 'react';
import Styled from 'styled-components';
import ciel_montagne_draft from '../images/noel/montagne2.svg';
import batiments1 from '../images/noel/batiments2b_pruned.webp';
import batiments1_fallback from '../images/noel/batiments2b_pruned.webp.png';
import batiments2 from '../images/noel/batiments2b_pruned_nocenter.webp';
import batiments2_fallback from '../images/noel/batiments2b_pruned_nocenter.webp';
import shakawah from '../images/noel/shakawah.png';
import titre from '../images/noel/titre.webp';
import titre_fallback from '../images/noel/titre.webp.png';
import star1 from '../images/noel/star1.svg';
import star2 from '../images/noel/star2.svg';
import star3 from '../images/noel/star3.svg';
import star4 from '../images/noel/star4.svg';
import star5 from '../images/noel/star5.svg';
import star6 from '../images/noel/star6.svg';
import star7 from '../images/noel/star7.svg';
import moon from '../images/noel/moon5.svg';
import traineau from '../images/noel/noel_above_fold2.webp';
import traineau_fallback from '../images/noel/noel_above_fold2.webp.png';
import foreground from '../images/noel/foreground4.webp';
import foreground_fallback from '../images/noel/foreground4.webp.png';
import foreground_light from '../images/noel/foreground3_light.png';
import costumeM from '../images/noel/costume_m.png';
import costumeMChapeau from '../images/noel/costume_m_chapeau.png';
import costumeMVeste from '../images/noel/costume_m_veste.png';
import costumeMPantalon from '../images/noel/costume_m_pantalon.png';
import costumeFChapeau from '../images/noel/costume_f_chapeau.png';
import giftRed from '../images/noel/gift.png';
import giftGreen from '../images/noel/gift-green.png';
import costumeFCape from '../images/noel/costume_f_cape.png';
import costumeFRobe from '../images/noel/costume_f_robe.png';
import costumeF from '../images/noel/costume_f.png';
import chatBubbleDeco from '../images/noel/bubble_deco2.png';
import chatBubbleTriangle from '../images/noel/bubble_triangle.png';
import RatioPlaceHolder from 'xureact/lib/module/components/layout/ratio-placeholder';
import { Aurora } from './aurora';
import { medias } from '../styles/medias';

function StreamerFace(props) {
  const coords = props.coords.split(',');
  const style = {
    left:`${100*(coords[0]-50)/1350}%`,
    top:`${100*coords[1]/675}%`,
    width: `${100*(coords[2]-coords[0])/1350}%`,
    height: `${100*(coords[3]-coords[1])/675}%`
  };
  
  return (
    <a className="traineau-link" style={style} href={props.href} target="_blank" rel="nofollow">
      <span className="traineau-link__name">
        {props.name}
      </span>
    </a>
  );
}

class ParrallaxBackground extends React.Component {
  prevScrollY = null;
  state = {
    bubbleContent: 'init',
  };
  
  constructor(props) {
    super(props);
    this.parrallax = this.parrallax.bind(this);
    this.handleSelectPart = this.handleSelectPart.bind(this);
  }
  
  reset_debug() {
    this.debugBlock.innerHTML = '';
  }
  
  debug(txt) {
    this.debugBlock.innerHTML += txt + '<br/>';
  }
  
  componentDidMount() {
    this.containerBlock = document.querySelector('.parrallax-background');
    this.stars = document.querySelector('.stars-background');
    this.aurora = document.querySelector('.stars-background-2');
    this.background = document.querySelector('.sky-background');
    this.traineau = document.querySelector('.traineau-wrapper');
    this.buildings1 = document.querySelector('.buildings-1');
    this.foreground = document.querySelector('.foreground');
    this.bubbleWrapper = document.querySelector('.bubble-wrapper');
    this.debugBlock = document.querySelector('.debug-block');
    requestAnimationFrame(this.parrallax);
    this.updateBubbleH(document.querySelector(`.bubble-init`));
    const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && navigator.userAgent.toLowerCase().indexOf('chrome/') === -1;
    if (isSafari) {
      document.body.classList.add('no-webp');
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const screenW = window.innerWidth;
    const bubbleTextElem = document.querySelector(`.bubble-${this.state.bubbleContent}`);
    console.log(`bubble-${this.state.bubbleContent}`);
    this.updateBubbleH(bubbleTextElem);
  }
  
  updateBubbleH(bubbleTextElem) {
    this.bubbleWrapper.style.height = `${bubbleTextElem.offsetHeight}px`;
  }
  
  parrallax() {
    const blockHeight = this.containerBlock.offsetHeight;
    const scrollY = document.documentElement.scrollTop;
    if (this.prevScrollY !== scrollY) {
      const screenH = window.innerHeight;
      const screenW = window.innerWidth;
      const visibleRatio = Math.min(1, screenH / blockHeight);
      const diffWithBottom = Math.max(0, blockHeight - (scrollY + screenH));
      const diffWithTop = Math.max(-blockHeight, -scrollY);
      //console.log(this.stars.offsetTop);
      this.prevScrollY = scrollY;
  
      this.reset_debug();
      this.debug(`blockHeight: ${blockHeight}`);
      this.debug(`screenW: ${screenW}`);
      this.debug(`screenH: ${screenH}`);
      this.debug(`scrollY: ${Math.round(scrollY)}`);
      this.debug(`diffWithBottom: ${Math.round(diffWithBottom)}`);
      this.debug(`diffWithTop: ${Math.round(diffWithTop)}`);
  
      this.changeY(this.stars, diffWithTop, 0.18);
      this.changeY(this.aurora, diffWithTop, 0.25);
      this.changeY(this.background, diffWithTop, -0.15);
      this.changeY(this.traineau, diffWithTop, -0.1);
      this.changeY(this.buildings1, diffWithTop, -0.05);
      this.changeY(this.foreground, diffWithBottom, 0.15*(1-visibleRatio));
      
      if (diffWithBottom / blockHeight < 0.1) {
        this.bubbleWrapper.style.opacity = 1.0;
      }
      else {
        this.bubbleWrapper.style.opacity = 0.0;
      }
    }
    requestAnimationFrame(this.parrallax);
  }
  
  changeY(domElement, scrollY, speed) {
    const blockHeight = this.containerBlock.offsetHeight;
    //const destY = scrollY * speed + blockHeight - domElement.offsetTop - domElement.offsetHeight;
    const destY = scrollY * speed;
    this.debug(`destY(${domElement.className.replace(/ratio-placeholder /, '')}): ${Math.round(destY)}`);
    domElement.style.transform = `translate(0, ${destY}px)`;
  }
  
  render() {
    const props = this.props;
    return (
      <div className={`${props.className} parrallax-wrapper`}>
        <div className="parrallax-background-wrapper">
          <div className={`parrallax-background`}>
            <div className="debug-block"/>
            <RatioPlaceHolder ratio={`${2600/3000}:1`} className="stars-background">
              <img src={star1} alt="star" className="star star1"/>
              <img src={star2} alt="star" className="star star2"/>
              <img src={star3} alt="star" className="star star3"/>
              <img src={star4} alt="star" className="star star4"/>
              <img src={star5} alt="star" className="star star5"/>
              <img src={star6} alt="star" className="star star6"/>
              <img src={star7} alt="star" className="star star7"/>
            </RatioPlaceHolder>
            <RatioPlaceHolder ratio={`${2600/3000}:1`} className="stars-background-2">
              <Aurora className="aurora"/>
              <img src={moon} alt="Moon" className="moon"/>
            </RatioPlaceHolder>
            <RatioPlaceHolder ratio={`${2683/1884}:1`} className="sky-background" />
            <div className="traineau-wrapper">
              <picture>
                <source srcSet={titre} type="image/webp" />
                <source srcSet={titre_fallback} type="image/png" />
                <img src={titre_fallback} className="noel-title" alt="Un Noël chez les Protopotes"/>
              </picture>
              <div className="traineau-rotate">
                <RatioPlaceHolder ratio={`${1350/675}:1`} className="traineau">
                  <picture>
                    <source srcSet={traineau} type="image/webp" />
                    <source srcSet={traineau_fallback} type="image/png" />
                    <img src={traineau_fallback} alt="Traineau du Père Noël avec des visages incrustés" useMap="#traineau-map"/>
                  </picture>
                  <div className="traineau-links">
                    <StreamerFace shape="rect" coords="72,553,173,648"    name="PrincessPirotess" href="https://www.twitch.tv/princesspirotess" />
                    <StreamerFace shape="rect" coords="208,307,303,454"   name="Spectrenoir06" href="https://www.twitch.tv/spectrenoir06" />
                    <StreamerFace shape="rect" coords="586,235,670,307"   name="Virtualabs" href="https://twitch.tv/virtualabs" />
                    <StreamerFace shape="rect" coords="500,396,600,470"   name="Hootie" href="https://www.twitch.tv/Hootie_L" />
                    <StreamerFace shape="rect" coords="510,311,608,380"   name="xurei" href="https://twitch.tv/xurei" />
                    <StreamerFace shape="rect" coords="719,189,798,286"   name="Tainalo2" href="https://www.twitch.tv/tainalo2" />
                    <StreamerFace shape="rect" coords="805,180,874,278"   name="CoutureEtPaillettes" href="https://www.twitch.tv/CoutureEtPaillettes" />
                    <StreamerFace shape="rect" coords="883,159,954,294"   name="Hippo_FabMaker" href="https://www.twitch.tv/Hippo_FabMaker" />
                    <StreamerFace shape="rect" coords="709,90,854,172"    name="SombrePigeon" href="https://www.twitch.tv/sombrepigeon" />
                    <StreamerFace shape="rect" coords="954,175,1017,299"  name="Tixlegeek" href="https://www.twitch.tv/tixlegeek" />
                    <StreamerFace shape="rect" coords="1017,156,1066,269" name="Nid_de_fees" href="https://www.twitch.tv/nid_de_fees" />
                    <StreamerFace shape="rect" coords="1036,100,1157,204" name="Le_mastoq" href="https://www.twitch.tv/le_mastoq" />
                    <StreamerFace shape="rect" coords="1210,282,1321,371" name="Durss" href="https://www.twitch.tv/durss" />
                    <StreamerFace shape="rect" coords="1200,115,1268,263" name="Fibertooth" href="https://www.twitch.tv/fibertooth" />
                    <StreamerFace shape="rect" coords="1271,101,1339,190" name="KleberMaker" href="https://www.twitch.tv/KleberMaker" />
                    <StreamerFace shape="rect" coords="311,409,375,509"   name="KathleenFabric" href="https://www.twitch.tv/KathleenFabric" />
                    <StreamerFace shape="rect" coords="378,375,463,471"   name="ioodyme" href="https://www.twitch.tv/ioodyme" />
                    </div>
                </RatioPlaceHolder>
              </div>
            </div>
            <RatioPlaceHolder ratio={`${1920/1515}:1`} className="buildings-1">
              <p className="description">
                Pour leur premier projet collaboratif, les Protopotes vous invitent à passer Noël chez eux lors d'un stream
                festif et interactif sur Twitch <strong>le Lundi 27 Décembre à 18h !</strong><br/><br/>
      
                Nous prenons le meilleur de nos compétences et construisons, cousons et programmons deux costumes de Père et
                Mère Noël, pour amener la bêtise et la joie partout dans le monde.<br/><br/>
      
                17 Protopotes s'activent tels de petits lutins, répartis en groupes multi-compétences pour créer 6 pièces de
                costumes aux fonctionnalités plus délirantes les unes que les autres !<br/><br/>
                Vous aurez la chance de rentrer dans l'atelier de la Mère et du Père Noël dès Novembre sur les streams des{' '}
                <a href="https://raid.protopotes.stream" target="_blank">chaînes Twitch</a>{' '}
                des Protopotes et être témoins de la fabrication des pièces… puis à en prendre plein les mirettes pendant le
                grand stream de reveal du 27 Décembre sur la chaîne Twitch <a href="https://www.twitch.tv/ioodyme" target="_blank">Ioodyme</a>.<br/><br/>
      
                {/*Vous êtes de curieux petits rennes ? Alors vous trouverez plus de détails en jetant un œil aux costumes ci-dessous.*/}
      
                Stay jolly and bright !
              </p>
              <a className="lutin-roof traineau-link" target="_blank" rel="nofollow" href="https://www.twitch.tv/shakawah">
                <span className="traineau-link__name">
                  Shakawah
                </span>
                <img src={shakawah} alt="Lutin sur le toit" className=""/>
              </a>
            </RatioPlaceHolder>
            <RatioPlaceHolder ratio={`${1915/975}:1`} className="foreground">
              <div className="foreground-light-wrapper">
                <RatioPlaceHolder ratio="341:975" className="foreground-light" />
              </div>
              <div className="costume-m-wrapper">
                <RatioPlaceHolder ratio="1:2" className="costume-m">
                  <img className={`${this.state.bubbleContent === 'costumeMChapeau'  ? 'active' : ''}`} data-part="costumeMChapeau"  src={costumeMChapeau}  alt="Bonnet"   onClick={this.handleSelectPart}/>
                  <img className={`${this.state.bubbleContent === 'costumeMVeste'    ? 'active' : ''}`} data-part="costumeMVeste"    src={costumeMVeste}    alt="Veste"    onClick={this.handleSelectPart}/>
                  <img className={`costume-m-pantalon ${this.state.bubbleContent === 'costumeMPantalon' ? 'active' : ''}`} data-part="costumeMPantalon" src={costumeMPantalon} alt="Pantalon" onClick={this.handleSelectPart}/>
                </RatioPlaceHolder>
              </div>
              <div className="costume-f-wrapper">
                <RatioPlaceHolder ratio="1:2" className="costume-f">
                  <img className={`${this.state.bubbleContent === 'costumeFChapeau'  ? 'active' : ''}`}               data-part="costumeFChapeau" src={costumeFChapeau} alt="Bonnet" onClick={this.handleSelectPart}/>
                  <img className={`costume-f-cape ${this.state.bubbleContent === 'costumeFCape'    ? 'active' : ''}`} data-part="costumeFCape"    src={costumeFCape}    alt="Cape"   onClick={this.handleSelectPart}/>
                  <img className={`costume-f-robe ${this.state.bubbleContent === 'costumeFRobe' ? 'active' : ''}`}    data-part="costumeFRobe"    src={costumeFRobe}    alt="Robe"   onClick={this.handleSelectPart}/>
                </RatioPlaceHolder>
              </div>
              <div className="gift-wrapper">
                <RatioPlaceHolder ratio="1000:1351" className="gift">
                  <img className={`${this.state.bubbleContent === 'gift'  ? 'active' : ''}`} data-part="gift" src={giftRed} alt="Cadeau" onClick={this.handleSelectPart}/>
                </RatioPlaceHolder>
              </div>
      
              <div className="bubble-wrapper">
                <div className="bubble-content">
                  <div className="bubble-content__content">
                    <div className={`bubble-text bubble-init`} hidden={this.state.bubbleContent !== 'init'}>
                      <p>
                        Clique sur un élément de costume pour plus de détails.
                      </p>
                    </div>
      
                    <div className={`bubble-text bubble-gift`} hidden={this.state.bubbleContent !== 'gift'}>
                      <h3>Les projets secrets</h3>
                      <p>
                        Créés par <a href="https://twitch.tv/fibertooth" target="_blank" rel="noreferrer">Fibertooth</a>,{' '}
                        <a href="https://twitch.tv/le_mastoq" target="_blank" rel="noreferrer">Le Mastoq</a>,{' '}
                        <a href="https://www.twitch.tv/Hippo_FabMaker" target="_blank" rel="noreferrer">Hippo_FabMaker</a>,{' '}
                        <a href="https://twitch.tv/princesspirotess" target="_blank" rel="noreferrer">PrincessPirotess</a>{' '}
                        et <a href="https://twitch.tv/shakawah" target="_blank" rel="noreferrer">Shakawah</a>
                      </p>
                      <p>
                        Ces magnifiques costumes méritent un live de reveal exceptionnel ! Fibertooth, Le Mastoq, Hippo_FabMaker, PrincessPirotess et Shakawah
                        vous préparent quelques surprises rendront cet événement inoubliable.
                      </p>
                    </div>
      
                    <div className={`bubble-text bubble-costumeMChapeau`} hidden={this.state.bubbleContent !== 'costumeMChapeau'}>
                      <h3>Le bonnet du Père Noël</h3>
                      <p>
                        Créé par <a href="https://twitch.tv/nid_de_fees" target="_blank" rel="noreferrer">Nid_De_Fees</a>,{' '}
                        <a href="https://www.twitch.tv/spectrenoir06" target="_blank" rel="noreferrer">Spectrenoir06</a>{' '}et{' '}
                        <a href="https://www.twitch.tv/sombrepigeon" target="_blank" rel="noreferrer">SombrePigeon</a>
                      </p>
                      <p>
                        Spectrenoir06, Nid_De_Fees et SombrePigeon s’occupent du bonnet du Père Noël !
                        Attention on vous prévient, prévoyez des lunettes de soleil il risque d'être un poil lumineux.
                      </p>
                    </div>
                    
                    <div className={`bubble-text bubble-costumeMVeste`} hidden={this.state.bubbleContent !== 'costumeMVeste'}>
                      <h3>La veste du Père Noël</h3>
                      <p>
                        Créé par <a href="https://www.twitch.tv/fibertooth" target="_blank" rel="noreferrer">Fibertooth</a>,{' '}
                        <a href="https://www.twitch.tv/durss" target="_blank" rel="noreferrer">Durss</a>{' '}et{' '}
                        <a href="https://www.twitch.tv/virtualabs" target="_blank" rel="noreferrer">Virtualabs</a>
                      </p>
                      <p>Durss, Fibertooth et Virtualabs font une veste de père Noël très énigmatique ! Cet ancien artefact est habité par un esprit de Noël très à l'écoute mais peu bavard.</p>
                    </div>
                  
                    <div className={`bubble-text bubble-costumeMPantalon`} hidden={this.state.bubbleContent !== 'costumeMPantalon'}>
                      <h3>Le pantalon du Père Noël</h3>
                      <p>
                        Créé par <a href="https://www.twitch.tv/CoutureEtPaillettes" target="_blank" rel="noreferrer">CoutureEtPaillettes</a>,{' '}
                        <a href="https://www.twitch.tv/tainalo2" target="_blank" rel="noreferrer">Tainalo2</a>{' '}et{' '}
                        <a href="https://www.twitch.tv/KleberMaker" target="_blank" rel="noreferrer">KleberMaker</a>
                      </p>
                      <p>
                        Vous trouvez qu'un cucuple c'est mignon, mais qu'un cucuple qui brille c'est mieux ?
                        Eh bien vous avez raison !<br/>
                        Le cyber-prototalon-christmas-max-V1 permet d'estimer la distance entre nos deux amoureux et de
                        déclencher divers évènement qui mettront Neodyme à mi chemin entre de la mignonnerie et une profonde gène.
                        Un pantalon qui risque de les faire vibrer...
                      </p>
                    </div>
                  
                    <div className={`bubble-text bubble-costumeFChapeau`} hidden={this.state.bubbleContent !== 'costumeFChapeau'}>
                      <h3>Le bonnet de la Mère Noël</h3>
                      <p>
                        Créé par <a href="https://www.twitch.tv/le_mastoq" target="_blank" rel="noreferrer">Le_Mastoq</a>{' '}et{' '}
                        <a href="https://www.twitch.tv/tixlegeek" target="_blank" rel="noreferrer">Tixlegeek</a>
                      </p>
                      <p>
                        Un chapeau de mère Noël luminescent et ... vivant ?! A vous de déterminer son apparence pour gagner des points.<br/>
                        Et il parait qu'il possède un talent particulier pour un instrument à vent.
                      </p>
                    </div>
                  
                    <div className={`bubble-text bubble-costumeFCape`} hidden={this.state.bubbleContent !== 'costumeFCape'}>
                      <h3>La capeline de la Mère Noël</h3>
                      <p>
                        Créé par <a href="https://www.twitch.tv/KathleenFabric" target="_blank" rel="noreferrer">KathleenFabric</a>{' '}et{' '}
                        <a href="https://www.twitch.tv/ioodyme" target="_blank" rel="noreferrer">Ioodyme</a>
                      </p>
                      <p>
                        À leur image (et un peu à celle de leurs lutins, vous comprendrez vite pourquoi), Ioodyme et
                        Kathleenfabric vous préparent une cape lumineuse certes, mais surtout bruyante !
                        Ambiance de Noël garantie en un geste !
                      </p>
                    </div>
                  
                    <div className={`bubble-text bubble-costumeFRobe`} hidden={this.state.bubbleContent !== 'costumeFRobe'}>
                      <h3>La robe de la Mère Noël</h3>
                      <p>
                        Créé par <a href="https://www.twitch.tv/Hootie_L" target="_blank" rel="noreferrer">Hootie_L</a>{' '}et{' '}
                        <a href="https://www.twitch.tv/xurei" target="_blank" rel="noreferrer">xurei</a>
                      </p>
                      <p>
                        Une robe haute en couleurs et en musique ! Son acolyte ne sera pas en reste grâce à son
                        accessoire pour y ajouter l'indispensable magie de Noël.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </RatioPlaceHolder>
          </div>
        </div>
      </div>
    )
  }
  
  handleSelectPart(e) {
    const part = e.target.getAttribute('data-part');
    this.setState(state => {
      return {
        ...state,
        bubbleContent: part,
      }
    });
  }
}

//language=SCSS
ParrallaxBackground = Styled(ParrallaxBackground)`
& {
  overflow-x: hidden;
  overflow-y: visible;
  @media screen and (${medias.smMax}) {
    width: 100vw;
  }

  .parrallax-background-wrapper {
    width: 100vw;
    @media screen and (${medias.smMax}) {
      width: 115vw;
      margin-left: -11vw;
    }
  }
  
  .parrallax-background {
    background: linear-gradient(#000 12%, #0C0041 75%);
    position: relative;
    overflow: hidden;
    padding-bottom: ${2900/1920 * 100}%;
    @media (max-aspect-ratio: 1/1) {
      padding-bottom: ${4700/1920 * 100}%;
    }
  }
  
  .debug-block {
    display: none;
    position: fixed;
    left: 0;
    top: 100px;
    height: 300px;
    width: 250px;
    background: yellow;
    z-index: 100;
    color: black;
    text-align: left;
    padding-top: 10px;
    padding-left: 10px;
  }
  
  .stars-background-2 {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    will-change: transform;
    
    .aurora {
      position: absolute !important;
      opacity: 1.0;
      top: 5%;
    }
    .moon {
      position: absolute !important;
      left: 76%;
      width: 33%;
      top: 0;
    }
  }
  .stars-background {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    will-change: transform;

    @keyframes star-opacity {
      0% { opacity: 0.5; }
      30% { opacity: 0.8; }
      50% { opacity: 0.7; }
      75% { opacity: 0.8; }
      100% { opacity: 0.5; }
    }
    .star {
      position: absolute !important;
      width: 2.5vw;
      
      opacity: 0.8;
      will-change: opacity;
      animation: star-opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .star1 { animation-delay: -0ms; left: 16%; top: 3.5%; }
    .star2 { animation-delay: -100ms; left: 4%; top: 18%; }
    .star3 { animation-delay: -400ms; left: 77%; top: 12%; }
    .star4 { animation-delay: -300ms; left: 72%; top: 26%; }
    .star5 { animation-delay: -200ms; left: 92%; top: 36%; }
    .star6 { animation-delay: -600ms; left: 22%; top: 25%; }
    .star7 { animation-delay: -500ms; left: 77%; top: 2%; }
  }

  .sky-background {
    position: absolute !important;
    left: 0;
    top: 50%;
    background: url(${ciel_montagne_draft});
    background-size: contain;
    will-change: transform;
    @media (max-aspect-ratio: 1/1) {
      top: 58%;
    }
  }

  .traineau-links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
  }
  .traineau-link {
    position: absolute;
    //background: rgba(255,0,0,0.5);
    color: transparent;
    &:hover {
      //border: solid 1px #eee;
      //border-radius: 6px;
      //color: #eee;
      .traineau-link__name {
        color: #eee;
        background: rgba(0,0,0,0.4);
        opacity: 1;
      }
    }
  }
  .traineau-link__name {
    display: inline-block;
    text-align: left;
    position: absolute;
    top: -1.2em;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.2vw;
    padding: 1px 3px;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  
  .lutin-roof .traineau-link__name {
    top: 0em;
  }
  
  .traineau-wrapper {
    text-align: center;
    position: absolute !important;
    width: 55%;
    top: 4%;
    left: 23%;
    height: 35%;
    will-change: transform;
    @media (max-aspect-ratio: 1/1) {
      width: 71%;
      left: 18%;
    }
    
    img {
      width: 100%;
    }
    img.noel-title {
      position: absolute !important;
      left: 30%;
      width: 40%;
    }
  }

  .traineau-rotate {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 35%;
    transform: rotate(15deg);
    transform-origin: center;

    @media (max-aspect-ratio: 1/1) {
      top: 29%;
      left: -6%;
    }

    .traineau-link__name {
      /*transform: rotate(-15deg);*/
      transform-origin: center;
      transform: translate(-50%, 0) rotate(-15deg);
    }
  }

  .traineau {
    position: absolute !important;
    left: 0;
    overflow: visible !important;
    //background-image: url(${traineau});
    /*body.no-webp & {
      background-image: url(${traineau_fallback});
    }*/
    @media (max-aspect-ratio: 1/1) {
      margin-left: -2vw;
    }
    background-size: contain;
  }
  
  .buildings-1 {
    position: absolute !important;
    //top: 33%;
    bottom: 14.8%;
    left: 0;
    overflow: visible !important;
    @media (max-aspect-ratio: 1/1) {
      bottom: 10.8%;
    }
    background-image: url(${batiments1});
    body.no-webp & {
      background-image: url(${batiments1_fallback});
    }
    background-size: contain;
    will-change: transform;
    //background: red;
    
    .lutin-roof {
      position: absolute !important;
      top: 4.2%;
      left: 11%;
      width: 3.6%;
      
      img {
        width: 100%;
      }
    }
    .shakawah-link {
      &:hover {
        border: solid 1px #eee;
        border-radius: 6px;
        color: #eee;
        .traineau-link__name {
          color: #111;
          background: #eee;
        }
      }
    }

    .description {
      position: absolute !important;
      top: 4%;
      width: 36%;
      left: 34%;
      color: #eee;
      font-size: 1.2vw;
      @media screen and (${medias.xsMax}) {
        font-size: 1.5vw;
        top: 0;
        text-shadow: 0 0 5px #000;
      }
      
      @media (max-aspect-ratio: 1/1) {
        font-size: 3vw;
        top: -88%;
        width: 70%;
        left: 18%;
      }
    }
  }

  .foreground {
    position: absolute !important;
    overflow: visible !important;
    bottom: -0.2%;
    left: 0;
    background-image: url(${foreground});
    body.no-webp & {
      background-image: url(${foreground_fallback});
    }
    background-size: contain;
    will-change: transform;
    //background: red;

    .foreground-light-wrapper {
      position: absolute !important;
      left: 24.2%;
      top: 0%;
      /*bottom: -0.25%;*/
      opacity: 1.0;
      width: 17.76% !important;
      will-change: opacity;
      animation: foreground-light-blink;
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      .foreground-light {
        background: url(${foreground_light});
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    @keyframes foreground-light-blink {
      0% { opacity: 1.0; }
      
      27.0% { opacity: 1.0; }
      29.7% { opacity: 0.8; }
      30.0% { opacity: 0.2; }
      30.3% { opacity: 0.5; }
      
      33.7% { opacity: 0.5; }
      34.0% { opacity: 0.2; }
      34.3% { opacity: 1.0; }
      
      100% { opacity: 1; }
    }

    // Costumes & chat bubble
    & {
      .costume-m-wrapper, .costume-f-wrapper {
        position: absolute !important;
        width: 15% !important;
        line-height: 0;

        img {
          width: 100%;
          cursor: pointer;
          opacity: 0;
          -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
          user-select: none;

          &:hover {
            opacity: 0.68;
          }

          &.active {
            opacity: 1.0;
          }
        }
      }
      
      .gift-wrapper {
        position: absolute !important;
        width: 7% !important;
        line-height: 0;
        left: 63.5%;
        bottom: 12%;

        img {
          width: 100%;
          cursor: pointer;
          opacity: 0;
          -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
          user-select: none;

          &:hover {
            opacity: 0.68;
          }

          &.active {
            opacity: 1.0;
          }
        }
        
        .gift {
          background-image: url(${giftGreen});
          background-size: contain;
        }
      }

      .costume-m-wrapper {
        left: 10%;
        bottom: 4%;
      }

      .costume-m {
        background-image: url(${costumeM});
        background-size: contain;
      }

      .costume-m-pantalon {
        margin-top: -25%;
      }

      .costume-f-cape {
        position: relative;
        z-index: 2;
      }

      .costume-f-robe {
        position: relative;
        margin-top: -50%;
        z-index: 1;
      }

      .costume-f-wrapper {
        left: 30%;
        bottom: 8%;
      }

      .costume-f {
        background-image: url(${costumeF});
        background-size: contain;
      }

      .bubble-wrapper {
        position: absolute !important;
        width: 41% !important;
        bottom: 49%;
        right: 2.5%;
        @media screen and (${medias.smMax}) {
          right: 4% !important;
          width: 52% !important;
        }
        
        border: solid 0.2vw #000;
        border-radius: 8vw;
        background: #fff;
        box-shadow: 1vw 1vw 3vw rgba(0, 0, 0, 0.3);
        opacity: 0.0;
        transition: opacity 0.3s ease-out, height 0.5s 0.1s ease-out;
        
        &:before {
          content: "";
          position: absolute;
          background-image: url(${chatBubbleDeco});
          background-size: contain;
          background-repeat: no-repeat;
          left: 0;
          top: 0;
          margin-left: -0.8vw;
          margin-top: -2vw;
          width: 19%;
          padding-bottom: 22%;
          transform: rotate(0deg);
          @media screen and (${medias.smMax}) {
            width: 18%;
          }
        }

        &:after {
          content: "";
          position: absolute;
          background-image: url(${chatBubbleTriangle});
          background-size: contain;
          background-repeat: no-repeat;
          right: 15%;
          bottom: -3.33vw;
          @media screen and (${medias.smMax}) {
            bottom: -4.65vw;
          }
          width: 28%;
          padding-bottom: 9%;
        }
        
        .bubble-text {
          transition: opacity 0.3s 0.3s ease-out;
          position: absolute;
          left: 0;
          padding: 8% 4.5%;
          z-index: 2;
          width: 100%;

          *, p {
            font-size: 2.8vw;
            color: #222;
            @media screen and (${medias.mdMin}) {
              font-size: 1.20vw;
            }
          }

          h3 {
            font-size: 3.20vw;
            @media screen and (${medias.mdMin}) {
              font-size: 1.8vw;
            }
            color: #215A4F;
          }

          &[hidden] {
            display: block;
            visibility: visible;
            transition: opacity 0.3s ease-out;
            opacity: 0.0;
            z-index: 1;
            pointer-events: none;
          }

          a {
            text-decoration: underline;
            color: #215A4F;

            &:hover {
              color: #842F23;
            }
          }
        }

        div.bubble-content {
          display: flex;
          flex-direction: column;
          //justify-content: center;
          align-items: center;
          padding: 0 15%;

          line-height: 3.2vw;
          @media screen and (${medias.mdMin}) {
            line-height: 1.75vw;
          }
        }
      }
    }
  }
}
`;

export { ParrallaxBackground };
