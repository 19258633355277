import * as React from "react"
import { Helmet } from 'react-helmet';
import "../style.scss";
import { graphql, StaticQuery } from 'gatsby';
import { ParrallaxBackground } from '../components/parrallax-background';
import { StaticImage } from 'gatsby-plugin-image';
import Styled from 'styled-components';
import traineau from '../images/noel/og_image.jpg';

// styles
const pageStyles = {
  color: "white",
  padding: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const paragraphStyles = {
  marginBottom: 48,
}

// markup
let IndexPage = (props) => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Un Noël chez les Protopotes</title>
        <link rel="canonical" href="https://www.protopotes.stream/noel/" />
        <meta name="description" content="Pour leur premier projet collaboratif, les Protopotes créent deux costumes de Père et Mère Noël en live sur Twitch!"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`https://www.protopotes.stream${traineau}`} />
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
        <meta property="og:url" content="https://www.protopotes.stream/noel/" />
        <meta property="og:description" content="Pour leur premier projet collaboratif, les Protopotes créent deux costumes de Père et Mère Noël en live sur Twitch!" />
        <meta property="og:image" content={`https://www.protopotes.stream${traineau}`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="675" />
        <meta property="og:title" content="Un Noël chez les Protopotes" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      {/*<Header/>*/}
      <StaticQuery query={graphql`
          query {
              file(relativePath: { eq: "lab_neodyme.jpg" }) {
                  id
              }
          }
      `} render={(data) => (
        <div className={props.className}>
          <div className="logo-wrapper">
            <a href="/">
              <StaticImage
                src="../images/logo.png"
                className="logo"
                alt="Les Protopotes"
              />
            </a>
          </div>
          <ParrallaxBackground/>
        </div>
      )} />
    </main>
  )
}

//language=SCSS
IndexPage = Styled(IndexPage)`
& {
  .logo-wrapper {
    position: fixed;
    width: 54px;
    height: 54px;
    margin-left: 0.2vw;
    margin-top: 0.2vw;
    z-index: 100;
    opacity: 0.3;
    transform: scale(0.8);
    
    &:hover {
      opacity: 0.75;
    }
  }
}
`;

export default IndexPage;
