import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'; //eslint-disable-line no-unused-vars
import Styled from 'styled-components';

/**
 * An aurora borealis, taken from https://codepen.io/osfa/pen/ZEYbRBr
 */
class Aurora extends React.Component {
  static propTypes = {};
  
  render() {
    const props = this.props;
    return (
      <div className={props.className}>
          {/*<div className='auror_bg'/>*/}
          <div className='aur_cont'>
            {Array(25).fill(0).map((_,i) => {
              return (
                <span className={`aur_${i}_skew`} key={i}>
                  <span className={`aur aur_${i}`}/>
                </span>
              );
            })}
          </div>
      </div>
    );
  }
}

//language=SCSS
Aurora = Styled(Aurora)`
& {
  width: 100%;
  margin:auto;
  height: 100vh;
  max-height:600px;
  position: relative;

  .auror_bg{
    position: absolute;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    max-width:2400px;
    max-height:2400px;
    animation-name: northern;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background: linear-gradient(rgba(102, 255, 153,0.2) 10%,rgb(55,0,55) 15%,black 100%);
    will-change: transform;
  }
  .aur_cont{
    margin: 0 auto;
    display: table;
    height: auto;
    width: 100%;
    
    ${Array(25).fill(0).map((_,i) => {
      //language=SCSS
      return `&.aur_${i}_skew {
        transform: skew(3deg, -5deg);
        box-shadow: 0 0 0 10px red;
      }`
    })}

    .aur{
      transform: skew(-0.06turn, 18deg);
      display:block;
      width: 0;
      min-height: 13vw;
      float:left;
      margin-left:4%;
      border-radius:5% 52% 30px 20px;
      opacity: 0.6;
      will-change: transform, opacity;

      ${Array(25).fill(0).map((_,i) => {
        //language=SCSS
        return `&.aur_${i} {
          box-shadow:rgb(${Math.random()*64}, ${Math.random()*155 + 100}, ${Math.random()*195 + 60}) 0px 0px 5vw 1vw;
          margin-top: ${Math.random()*7.3 - 2}vw;
          animation: topup ${Math.random()*15000 + 3000}ms infinite linear;
        }`
      })}
    }
  }

  @keyframes topup{
    0%, 100% { transform: translatey(2vw) skew(3deg, -5deg); filter: hue-rotate(0deg); opacity: 0.5; }
    50% { transform: translatey(5vw) skew(-3deg, 6deg); filter: hue-rotate(30deg); opacity: 0.65; }
  }

  @keyframes northern {
    0% {transform: translate(5%, -2%);}
    25% {transform: translate(10%, 7%);}
    40% {transform: rotate(-10deg);}
    60% {transform: translate(7%, -2%);}
    85% {transform: translate(6%, 3%) rotate(12deg);}
    100% {transform: none;}
  }
}
`;

export { Aurora };
